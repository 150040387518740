import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Filings',
        component: () => import('@/views/Filings')
    }
    // {
    //     path: '/Element',
    //     name: 'Element',
    //     component: () => import('@/views/Element')
    // },
    // {
    //     path: '/login',
    //     name: 'Login',
    //     component: () => import('@/views/Login')
    // },
    // {
    //     path: '/home',
    //     name: 'HomeView',
    //     component: () => import('@/views/HomeView')
    // },
    // {
    //     path: '/pdf',
    //     name: 'Pdf',
    //     component: () => import('@/views/Pdf')
    // },
    // {
    //     path: '/video',
    //     name: 'Video',
    //     component: () => import('@/views/Video')
    // }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
